import Education from "../Entities/Education";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import { ProfileConfig } from "../Resources/Endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createEducationForm = (
  dataList,
  setDataFunction,
  showErrorFunction,
  setShowEdit
) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    start: Yup.string().required("Start date is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format YYYY-MM-DD"),
    ende: Yup.string().required("Start date is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format YYYY-MM-DD"),
    subject_area: Yup.string().required("Required"),
  });

  const onSubmit = async (values, actions) => {
    values.start = typeof values.start === 'string' ? values.start.split("T")[0] : null;
    values.ende = typeof values.ende === 'string' ? values.ende.split("T")[0] : null;
    if (values.id > 0) {
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.edit(
        ProfileConfig.endpoints.education.edit,
        payload
      );
      listHandler.updateList(
        response,
        payload,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    } else {
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.createNew(
        ProfileConfig.endpoints.education.new,
        payload
      );
      listHandler.newToList(
        response,
        dataList,
        setDataFunction,
        showErrorFunction,
        true
      );
    }

    setShowEdit(false);
  };

  return {
    initialValues: Education,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  const date = new Date();
  let Profile = {
    id: editData.id,
    name: editData.name,
    subject_area: editData.subject_area,
    start: editData.start
      ? editData.start.split("T")[0]
      : date.toISOString().split("T")[0],
    ende: editData.ende
      ? editData.ende.split("T")[0]
      : date.toISOString().split("T")[0],
    description: editData.description,
    profile: editData.profile,
  };

  return Profile;
};

export default { createEducationForm, setEditData };
