import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Brand from "./Brand";
import { useTranslation } from "react-i18next";

import ProfileDropdown from "../../LoggedIn/ProfileDropdown";

const Header = ({ sidebar = false, children = null }) => {

  const {t} = useTranslation();

  return (
    <header className="header" style={{ padding: "10px" }}>
      <div className="header__inner">
        <Brand />
        <div className="header__content">
          <div className="header__content-start">
            {sidebar ? (
              <button
                type="button"
                className="nav-toggler header__btn btn btn-icon btn-sm"
                aria-label="Nav Toggler"
                onClick={() => {
                  const root = document.getElementById("root");
                  if (
                    window.innerWidth < 992 ||
                    (!root.classList.contains("mn--min") &&
                      !root.classList.contains("mn--max"))
                  ) {
                    root.classList.toggle("mn--show");
                  } else {
                    root.classList.toggle("mn--min");
                    root.classList.toggle("mn--max");
                  }
                }}
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
            ) : null}
          </div>
          {children}
          <ProfileDropdown />
        </div>
      </div>
    </header>
  );
};

export default Header;
