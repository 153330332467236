import { create } from "apisauce";
import getSettings from "../config/settings";

const settings = getSettings();

const apiClient = create({
  baseURL: settings.apiUrl,
});

const baseURL = apiClient.getBaseURL();
apiClient.addAsyncRequestTransform(async (request) => {
  let authToken;
  let userId;
  const completeURL = `${baseURL}${request.url}`;
//  console.log(`Request URL: ${completeURL}`);
  if (typeof authStorage !== "undefined") {
    authToken = await authStorage.getToken();
  } else {
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    userId = JSON.parse(sessionStorage.getItem("localId"));
    authToken = userToken;
  }
  if (!authToken) return;
  request.headers["x-auth-token"] = authToken;
  request.headers["x-auth-id"] = userId;
});

apiClient.addResponseTransform((response) => {
  if (response.status === 500) {
    window.location.replace("/uuuups");
  }
  if (
    response.status === 403 &&
    window.location.hostname === "stage.bewerbungshelferlein.de"
  ) {
    window.location.replace("/datareset");
  }
  if (response.status === 422) {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("localId");
    window.location.replace("/requestemailvalidation");
  }
});

export default apiClient;
