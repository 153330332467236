import { Form } from "react-bootstrap";

const DateField = ({ formik, formClass = null, label = null, valueName, style={ width: "100%" }, onChangeFunction = null }) => {
  return (
    <Form.Group
      className={formClass ? formClass : "form-inline"}
      controlId={valueName}
      style={style}
    >
       {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        type="date"
        name={valueName}
        placeholder={label}
        value={formik.values[valueName]}
        isInvalid={!!formik.errors[valueName]}
        onChange={typeof onChangeFunction === "function" ? (e) => { onChangeFunction(e, formik)} : formik.handleChange}
        style={style}
      />
    </Form.Group>
  );
};

export default DateField;
