import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

const CTASection = () => {
  return (
    <Container
      fluid
      className="cta-section"
      style={{ borderRadius: "1.25rem", background: "#9FCC2E" }}
    >
      <Row className="justify-content-center align-items-center text-center">
        <Col md={8} lg={6}>
          <h1 className="cta-heading">
            <Trans i18nKey="Landingpage.CTASection.Boost" />
          </h1>
          <p className="cta-subheading">
            <Trans i18nKey="Landingpage.CTASection.StartFree" />
          </p>
          <Button as={Link} to="/signup" className="cta-button" variant="light">
            <Trans i18nKey="Landingpage.CTASection.Button" />
          </Button>
          <p className="cta-small-text mt-3">
            <Link
              to={
                "https://chromewebstore.google.com/detail/bewerbungshelferleinde/fmepoclkebcklfggldfepfpgmcnompgp"
              }
              target="_blank"
              style={{color: "white"}}
            >
              <Trans i18nKey="Landingpage.CTASection.Chromeplugin" />
            </Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default CTASection;
