import NewModal from "../../../Shared/Modals/NewModal";
import { useFormik } from "formik";
import { ApplicationConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import TextArea from "../../../Shared/Form/TextArea";
import DateField from "../../../Shared/Form/DateTimeField";
import KeyValueForm from "@serie3/common/Form/shared/KeyValueForm";
import SaveButton from "../../../Shared/Buttons/SaveButton";
import { useTranslation } from "react-i18next";

const AppoinmentModal = ({
  show,
  setShow,
  appointments,
  setAppointments,
  application,
}) => {
  const appointmentForm = KeyValueForm.createKeyValueForm(
    appointments,
    setAppointments,
    null,
    null,
    ApplicationConfig.endpoints.appointments.new,
    ApplicationConfig.endpoints.appointments.edit
  );

  const formik = useFormik(appointmentForm);

  const saveAppointment = () => {
    formik.setFieldValue("key_name", "appointment");
    formik.setFieldValue("application.id", application.id);
  }

  const { t } = useTranslation();

  const SaveAppointment = () => {
    return (
      <SaveButton formik={formik} submitFunction={saveAppointment}/>
    )
  }

  return (
    application && (
      <NewModal show={show} setShow={setShow} ExtraButton={SaveAppointment} title={t("Backend.Applications.Modal.Appointments.Title")}>
        <DateField formik={formik} label={t("Backend.Applications.Modal.Appointments.Date")} valueName={"appointment_date"} />
        <TextArea formik={formik} rows={10} label={t("Backend.Applications.Modal.Appointments.Note")} valueName={"value"} formClass={"mt-3"}/>
      </NewModal>
    )
  );
};

export default AppoinmentModal;
