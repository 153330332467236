import ContentWrapper from "../Shared/Basics/ContentWrapper";
import Header from "../Shared/Basics/Header";
import NotLoggedInHeader from "./NotLoggedIn/NotLoggedInHeader";
import LoginRouter from "./Login/LoginRouter";

const Notloggedin = ({ setToken, token, showLogin, setShowLogin }) => {
  return (
    <>
      <Header>
        <NotLoggedInHeader token={token} setToken={setToken}  showLogin={showLogin} setShowLogin={setShowLogin}/>
      </Header>
      <ContentWrapper setShowLogin={setShowLogin}>
        <LoginRouter setToken={setToken} />
      </ContentWrapper>
    </>
  );
};

export default Notloggedin;
