import { Button, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../styles/landingpage.scss";
import { Trans } from "react-i18next";
import HowItWorksComponent from "./Landingpage/HowItWorksComponent";
import imageSimpleCV from "@serie3/bewerbungshelferleinapi/assets/img/CVs/imageSimple.png";
import imageElegantCv from "@serie3/bewerbungshelferleinapi/assets/img/CVs/imageElegant.png";
import simple1 from "@serie3/bewerbungshelferleinapi/assets/img/CVs/simple1.png";
import header_color from "@serie3/bewerbungshelferleinapi/assets/img/CVs/header_color.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import waves from "../../../assets/img/landingpage/waves.png";
import AISection from "./Landingpage/AISection";
import FlowSection from "./Landingpage/FlowSection";
import FeatureSection from "./Landingpage/FeatureSection";
import CTASection from "./Landingpage/CTASection";
import BlogSection from "./Landingpage/BlogSection";

const Landingpage = () => {
  const { t } = useTranslation();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    const originalStyle = document.body.style.background;

    document.body.style.background = `url(${waves}) no-repeat top center`;
    document.body.style.backgroundSize = "100% auto";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.minHeight = "1000px";

    const contentElement = document.getElementById("content");
    if (contentElement) {
      contentElement.style.background = "transparent";
    }
    document.addEventListener("scroll", function () {
      const scrollPosition = window.pageYOffset;
      document.body.style.backgroundPosition =
        "center " + -scrollPosition * 0.5 + "px";
    });

    return () => {
      document.body.style.background = originalStyle;
    };
  }, []);

  return (
    <Container className="landingpage">
      <Row className="align-items-center">
        <Col md={8} className="mx-auto">
          <div className="text-center" style={{ color: "#FFFFFF" }}>
            <p className="CTAText mb-3" style={{fontWeight: "bold", fontSize: "1.3rem"}}>{t("Landingpage.Eyebrow")}</p>
            <h1
              className="CTA"
              style={{ color: "#FFFFFF", marginTop: "-15px" }}
            >
              <Trans i18nKey="Landingpage.Header">
                Make your
                <span style={{ color: "#9FCC2E" }}>applications easy!</span>
              </Trans>
            </h1>
            <p className="CTAText mb-3">
              <Trans i18nKey="Landingpage.CTAText" />
            </p>
            <Link to={"/signup"}>
              <Button className="btn btn-lgs btn-success mb-5 startbutton">
                {t("Landingpage.StartNowButton")}
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="text-center mb-4" style={{ background: "none" }}>
          <Carousel autoPlay={false} infinite={true} responsive={responsive}>
            <div>
              <img
                src={imageElegantCv}
                style={{ borderRadius: "10px" }}
                alt="cvTemplate elegant"
              />
            </div>
            <div>
              <img
                src={header_color}
                style={{ borderRadius: "10px" }}
                alt="cvTemplate header color"
              />
            </div>
            <div>
              <img
                src={simple1}
                style={{ borderRadius: "10px" }}
                alt="cvTemplate simple1"
              />
            </div>
            <div>
              <img
                src={imageSimpleCV}
                style={{ borderRadius: "10px" }}
                alt="cvTemplate simple"
              />
            </div>
          </Carousel>
        </div>
      </Row>
      <HowItWorksComponent />
      <AISection />
      <FlowSection />
      <FeatureSection />
      <CTASection />
      <BlogSection />
    </Container>
  );
};

export default Landingpage;
