import listHandler from "@serie3/common/Domain/listHandler";
import { ApplicationConfig } from "../../Profile/Resources/Endpoints";
import crudApi from "@serie3/common/api/crudApi";
import ToastMessages from "../../../../web/src/ToastMessages";

const deleteAction = (applicationId, index, setApplications, applications) => {
  const deleteUrl = ApplicationConfig.endpoints.delete;
  listHandler.deleteFromList(
    index,
    crudApi.del(deleteUrl, applicationId),
    setApplications,
    applications
  );
};

const editAction = (application, setEditApplication, setShowForm) => {
  setEditApplication(application);
  setShowForm(true);
};


const removeFromList = (application, applications, setApplications, message = "Archived")  =>{
  const modifiedList =  listHandler.deleteWithoutCall(application, applications);
  setApplications([...modifiedList]);
  ToastMessages.SuccessfulSave(message);
}

export {editAction, deleteAction, removeFromList}