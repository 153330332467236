import { Container, Row, Col, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import blogclient from "@serie3/common/api/blogClient";
import getSettings from "@serie3/common/config/settings";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";

const BlogSection = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const settings = getSettings();
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const response = await blogclient.get("/");
      if (response.status === 200) {
        setBlogPosts([...response.data.subpages]);
      }
    };
    loadData();
  }, []);

  return (
    <Container fluid className="blog-section">
      <Row className="text-center mb-5 justify-content-center">
        <Col md={6} className="mb-3">
          <h5><Trans i18nKey="Landingpage.BlogSection.Subtitle" /></h5>
          <h2><Trans i18nKey="Landingpage.BlogSection.Title" /></h2>
          <p className="subtitle">
          <Trans i18nKey="Landingpage.BlogSection.Description" />
          </p>
        </Col>
      </Row>
      <Row>
        {blogPosts.slice(0, 3).map((post) => {
          const imageUrl = post.image
            ? settings.blogUrl + post.image
            : "https://felixtravelblog.de/fileadmin/templates/felix/travelblog/template/images/backgroundTravelBlog.jpg";
          const slug = post.slug && post.slug.replace("blogapi", "blog");

          return (
            <Col
              key={post.id}
              md={4}
              onClick={() => {
                navigate(slug);
              }}
              style={{ cursor: "pointer" }}
              className="mb-3"
            >
              <Card className="blog-card" style={{height: "100%"}}>
                <Card.Img src={imageUrl} />
                <Card.Body>
                  <div className="author-section">
                    <div className="author-info">
                      <h5>{post.title}</h5>
                    </div>
                  </div>
                  <Card.Text dangerouslySetInnerHTML={{ __html: post.description }} />
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default BlogSection;
