import Application from "../Entities/Application";
import * as Yup from "yup";
import crudApi from "@serie3/common/api/crudApi";
import { ApplicationConfig } from "../Resources/Endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createApplicationForm = (
  dataList = null,
  setDataFunction = null,
  setShowEdit = null
) => {
  const validationSchema = Yup.object({
    job_title: Yup.string().required("Required"),
    page: Yup.string().required("Required"),
  });

  const onSubmit = async (values, actions) => {
    //needed because here is nothing to modify with the profile. Also once the application is done with the profile it should not be ediable anymore
    if (values.hasOwnProperty("profile")) {
      delete values.profile;
    }

    if (values.hasOwnProperty("application_status")) {
      delete values.application_status;
    }

    if(values.follow_up === ''){
      delete values.follow_up;
    }

    const payload = JSON.stringify(values);
    let response;
    if (values.id > 0) {
      response = await crudApi.edit(ApplicationConfig.endpoints.edit, payload);

      if (dataList !== null) {
        listHandler.updateList(
          response,
          JSON.stringify(response.data),
          dataList,
          setDataFunction
        );
      }
    } else {
      response = await crudApi.createNew(
        ApplicationConfig.endpoints.new,
        payload
      );

      if (dataList !== null) {
        listHandler.newToList(response, dataList, setDataFunction, null, true);
      }
    }
    if (response.status === 200 && typeof setShowEdit === "function") {
      setShowEdit(false);
    }
  };

  return {
    initialValues: Application,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  let Application = {
    id: editData.id,
    hireing_company: editData.hireing_company,
    job_title: editData.job_title,
    link: editData.link,
    page: editData.page,
    location: editData.location,
    status: editData.satus,
    recipient_name: editData.recipient_name,
    mail_address: editData.mail_address,
    follow_up: editData.follow_up,
    mail_subject: editData.mail_subject,
  };

  return Application;
};

export default { createApplicationForm, setEditData };
