import ContentWrapper from "../../Shared/Basics/ContentWrapper";
import Profile from "./Profile";
import { Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import { createNewProfile } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/Buttons";
import { useNavigate } from "react-router-dom";
import NewButtonWithIcon from "../../Shared/Buttons/NewButtonWithIcon";
import PreviewModal from "../../Shared/Modals/PreviewModal";
import FileDropzone from "../../Shared/Modals/FileDropzone";
import NewModal from "../../Shared/Modals/NewModal";

const Profiles = () => {
  const { t } = useTranslation();
  const [profiles, setProfiles] = useState([]);
  const [showPayModal, setShowPayModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewId, setPreviewId] = useState(-1);
  const [profileTitle, setProfileTitle] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showChatGPTWarning, setShowChatGPTWarning] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const endpoint = ProfileConfig.endpoints.getAll;
    crudApi.getAll(endpoint, setProfiles, "profile");
  }, []);

  const forwardToEdit = (response) => {
    if (response.ok) {
      let profileId = response.data.profileId;
      navigate("edit/" + profileId);
    }
  };

  const proceedToChatGPT = () => {
    setShowChatGPTWarning(false);
    setShowUploadModal(true);
  };

  return (
    <>
      <NewModal
        show={showChatGPTWarning}
        setShow={setShowChatGPTWarning}
        onExit={proceedToChatGPT}
        title={t("Backend.Profiles.ChatGPTWarning.Title")}
        closeButtonText={t("Backend.Profiles.ChatGPTWarning.CloseButtonText")}
      >
        <Trans i18nKey={"Backend.Profiles.ChatGPTWarning.Text"} />
      </NewModal>

      <NewModal
        show={showUploadModal}
        setShow={setShowUploadModal}
        title={t("Backend.Profiles.ImportCV")}
      >
        <FileDropzone
          uploadUrl={"profile/importcv"}
          edit={false}
          setName={false}
          additionalFunction={forwardToEdit}
        />
      </NewModal>

      <ContentWrapper
        title={t("Backend.Profiles.Index.Title")}
        welcomeText={t("Backend.Profiles.Index.Subtitle")}
        titleText={t("Backend.Profiles.Index.DashboardText")}
      >
        <PreviewModal
          show={showPreview}
          setShow={setShowPreview}
          id={previewId}
          setId={setPreviewId}
          title={profileTitle}
          downloadUrl={ProfileConfig.endpoints.download + previewId}
        />

        <Row>
          <Col>
            <div className="mt-4 mb-3 d-flex flex-wrap gap-2">
              <NewButtonWithIcon
                title={t("Backend.Buttons.ImportProfile")}
                showPayModal={showPayModal}
                setShowPayModal={setShowPayModal}
                color={"info"}
                handleClick={() => {
                  setShowChatGPTWarning(true);
                }}
                icon="psi-file-upload"
              />
              <NewButtonWithIcon
                title={t("Backend.Buttons.NewProfile")}
                showPayModal={showPayModal}
                setShowPayModal={setShowPayModal}
                handleClick={() => {
                  createNewProfile(setShowPayModal, navigate);
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          {profiles.length > 0 &&
            profiles.map((profile, index) => (
              <Profile
                index={index}
                key={profile.id}
                profile={profile}
                profiles={profiles}
                setProfiles={setProfiles}
                setPreviewId={setPreviewId}
                setShowPreview={setShowPreview}
                setProfileTitle={setProfileTitle}
              />
            ))}
        </Row>
      </ContentWrapper>
    </>
  );
};
export default Profiles;
