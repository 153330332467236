import StatusDropdown from "../../Applications/Subcomponents/StatusDropdown";
import { Button, Dropdown } from "react-bootstrap";
import CustomToggle from "../../../Shared/Dropdown/CustomToggle";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import updateStatus from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/UpdateApplicationStatus";

const ApplicationStatusDropdown = ({
  application,
  updateView,
  setUpdateView,
  small = false,
  setShowAppointment = null,
  setEditApplication = null,
  removeFromList = null,
  archive = false,
}) => {
  const [applicationStatus, setApplicationStatus] = useState(
    application.status ? application.status : "Todo"
  );
  const translationKey = `Backend.Applications.Status.${
    applicationStatus.charAt(0).toUpperCase() + applicationStatus.slice(1)
  }`;

  const { t } = useTranslation();
  return (
    <div className="btn-group">
      <Button
        className={`btn btn-info ${small ? "btn-sm" : ""}`}
        style={{ minWidth: "142px" }}
      >
        {t(translationKey)}
      </Button>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <button
            type="button"
            className={`btn btn-info dropdown-toggle dropdown-toggle-split ${
              small ? "btn-sm" : ""
            }`}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ marginLeft: "-5px" }}
          >
            <span className="visually-hidden"></span>
          </button>
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            position: "absolute",
            inset: "0px auto auto 0px",
            margin: "0px",
            transform: "translate(0px, 39px)",
            zIndex: "9999",
          }}
        >
          <StatusDropdown
            text={t("Backend.Applications.Status.Todo")}
            action={() => {
              updateStatus(
                application,
                "todo",
                setApplicationStatus,
                updateView,
                setUpdateView
              );
              if (typeof removeFromList === "function" && archive) {
                removeFromList();
              }
            }}
          />
          <div className="dropdown-divider" />
          <StatusDropdown
            text={t("Backend.Applications.Status.Applied")}
            action={() => {
              updateStatus(
                application,
                "applied",
                setApplicationStatus,
                updateView,
                setUpdateView
              );
              if (typeof removeFromList === "function" && archive) {
                removeFromList();
              }
            }}
          />
          <div className="dropdown-divider" />
          <StatusDropdown
            text={t("Backend.Applications.Status.Waiting")}
            action={() => {
              updateStatus(
                application,
                "waiting",
                setApplicationStatus,
                updateView,
                setUpdateView
              );
              if (typeof removeFromList === "function" && archive) {
                removeFromList();
              }
            }}
          />
          <div className="dropdown-divider" />
          <StatusDropdown
            text={t("Backend.Applications.Status.Appointment")}
            action={() => {
              updateStatus(
                application,
                "appointment",
                setApplicationStatus,
                updateView,
                setUpdateView
              );
              if (
                typeof setShowAppointment === "function" &&
                typeof setEditApplication === "function"
              ) {
                setShowAppointment(true);
                setEditApplication(application);
              }
              if (typeof removeFromList === "function" && archive) {
                removeFromList();
              }
            }}
          />
          <div className="dropdown-divider" />
          <StatusDropdown
            text={t("Backend.Applications.Status.Canceled")}
            action={() => {
              updateStatus(
                application,
                "canceled",
                setApplicationStatus,
                updateView,
                setUpdateView
              );
              if (typeof removeFromList === "function" && !archive) {
                removeFromList();
              }
            }}
          />
          <div className="dropdown-divider" />
          <StatusDropdown
            text={t("Backend.Applications.Status.Noreply")}
            action={() => {
              updateStatus(
                application,
                "noreply",
                setApplicationStatus,
                updateView,
                setUpdateView
              );
              if (typeof removeFromList === "function" && !archive) {
                removeFromList();
              }
            }}
          />
          <div className="dropdown-divider" />
          <StatusDropdown
            text={t("Backend.Applications.Status.Rejected")}
            action={() => {
              updateStatus(
                application,
                "rejected",
                setApplicationStatus,
                updateView,
                setUpdateView
              );
              if (typeof removeFromList === "function" && !archive) {
                removeFromList();
              }
            }}
          />
          <div className="dropdown-divider" />
          <StatusDropdown
            text={t("Backend.Applications.Status.Accepted")}
            action={() => {
              updateStatus(
                application,
                "accepted",
                setApplicationStatus,
                updateView,
                setUpdateView
              );
              if (typeof removeFromList === "function" && !archive) {
                removeFromList();
              }
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ApplicationStatusDropdown;
