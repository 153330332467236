import simpleCv from "@serie3/bewerbungshelferleinapi/assets/simple.jpg";
import imageElegantCv from "@serie3/bewerbungshelferleinapi/assets/img/CVs/imageElegant.png";
import imageDarkCv from "@serie3/bewerbungshelferleinapi/assets/img/CVs/dark.png";
import simple1 from "@serie3/bewerbungshelferleinapi/assets/img/CVs/simple1.png";
import header_color from "@serie3/bewerbungshelferleinapi/assets/img/CVs/header_color.png";
import { Row, Col, Button } from "react-bootstrap";
import Card from "../../../../Shared/Card";
import { useState } from "react";
import { Trans } from "react-i18next";

const ProfileSettings = ({ formik, profileType, setProfileType, profileLang, setProfileLang }) => {
  const [templateName, setTemplateName] = useState(formik.values.cv_template);


  const setCvTemplate = (name) => {
    formik.setFieldValue("cv_template", name);
    formik.handleSubmit();
    setTemplateName(name);
  };

  const changeProfileType = () => {
    formik.setFieldValue("freelance", !profileType);
    formik.handleSubmit();
    setProfileType(!profileType);
  };

  const changeProfileLang = () => {
    let newLang = profileLang === 'de' ? 'en' : 'de';
    formik.setFieldValue("profile_language", newLang);
    formik.handleSubmit();
    setProfileLang(newLang);
  };

  const borderStyle = "1px solid black";

  return (
    <>
      <Row>
        <h3>
          <Trans i18nKey="Backend.Profiles.Edit.Settings.ProfileType">
            Profile Type
          </Trans>
        </h3>
        <hr style={{ maxWidth: "95%" }} />
        <Col className="col-sm-6 col-md-6 mb-5">
          <Button
            onClick={() => {
              changeProfileType();
            }}
            className={profileType === false ? 'btn btn-success' : 'btn'}
          >
            {profileType === false ? (
              <Trans i18nKey="Backend.Profiles.Edit.Settings.ChangeProfileTypeFreelance" onClick={() => {changeProfileType()}} />
            ) : (
              <Trans i18nKey="Backend.Profiles.Edit.Settings.ChangeProfileTypeEmployee" onClick={() => {changeProfileType()}} />
            )}
          </Button>
        </Col>
      </Row>
      <Row>
        <h3>
          <Trans i18nKey="Backend.Profiles.Edit.Settings.ProfileLanguage">
            Profile Language
          </Trans>
        </h3>
        <hr style={{ maxWidth: "95%" }} />
        <Col className="col-sm-6 col-md-6 mb-5">
          <Button
            onClick={() => {
              changeProfileLang();
            }}
            className={profileLang === 'de' ? 'btn btn-success' : 'btn'}
          >
            {profileLang === 'de' ? (
              <Trans i18nKey="Backend.Profiles.Edit.Settings.ChangeProfileLanguageEn" onClick={() => {changeProfileLang()}} />
            ) : (
              <Trans i18nKey="Backend.Profiles.Edit.Settings.ChangeProfileLanguageDe" onClick={() => {changeProfileLang()}} />
            )}
          </Button>
        </Col>
      </Row>
      <Row>
        <h3>
          <Trans i18nKey="Backend.Profiles.Edit.Settings.EmployeeTemplates">
            CV Template
          </Trans>
        </h3>
        <hr style={{ maxWidth: "95%" }} />
        <Col className="col-sm-4 col-md-4">
          <Card title="Full (freelance ready)">
            <img
              src={simpleCv}
              alt="Full"
              style={{
                maxWidth: "100%",
                maxHeight: "321px",
                ...(templateName === "full" && {
                  border: borderStyle,
                }),
              }}
              onClick={() => {
                setCvTemplate("full");
              }}
            />
          </Card>
        </Col>
        <Col className="col-sm-4 col-md-4">
          <Card title="Modern">
            <img
              src={imageElegantCv}
              alt="modernCV"
              style={{
                maxWidth: "100%",
                maxHeight: "321px",
                ...(templateName === "elegant" && {
                  border: borderStyle,
                }),
              }}
              onClick={() => {
                setCvTemplate("elegant");
              }}
            />
          </Card>
        </Col>
        <Col className="col-sm-4 col-md-4">
          <Card title="Dark">
            <img
              src={imageDarkCv}
              alt="darkCV"
              style={{
                maxWidth: "100%",
                maxHeight: "321px",
                ...(templateName === "dark" && {
                  border: borderStyle,
                }),
              }}
              onClick={() => {
                setCvTemplate("dark");
              }}
            />
          </Card>
        </Col>
        <Col className="col-sm-4 col-md-4">
          <Card title="Simple_1">
            <img
              src={simple1}
              alt="darkCV"
              style={{
                maxWidth: "100%",
                maxHeight: "321px",
                ...(templateName === "simple_1" && {
                  border: borderStyle,
                }),
              }}
              onClick={() => {
                setCvTemplate("simple_1");
              }}
            />
          </Card>
        </Col>
        <Col className="col-sm-4 col-md-4">
          <Card title="color_header">
            <img
              src={header_color}
              alt="header_color"
              style={{
                maxWidth: "100%",
                maxHeight: "321px",
                ...(templateName === "color_header" && {
                  border: borderStyle,
                }),
              }}
              onClick={() => {
                setCvTemplate("color_header");
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProfileSettings;
