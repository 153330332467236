const ApplicationCompose = {
    mail_text: '',
    mail_address: '',
    mail_subject: '',
    updated_title: '',
    fit_percentage: '',
    follow_up: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
}

export default ApplicationCompose;

