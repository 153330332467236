import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import blogclient from "@serie3/common/api/blogClient";
import getSettings from "@serie3/common/config/settings";
import Header from "../../Shared/Basics/Header";
import ContentWrapper from "../../Shared/Basics/ContentWrapper";
import { useNavigate } from "react-router-dom";
import NotLoggedInHeader from "../NotLoggedIn/NotLoggedInHeader";

const Blogpage = ({ setToken, token, showLogin, setShowLogin }) => {
  const { splat } = useParams();
  const [blogContent, setBlogContent] = useState("");
  const [header, setHeader] = useState("");
  const [headerImage, setHeaderImage] = useState("");
  const settings = getSettings();
  const navigate = useNavigate();

  const blogUrl =
    window.location.protocol + "//" + window.location.host + "/blog/";

  useEffect(() => {
    const loadData = async () => {
      const response = await blogclient.get(splat);
      if (response.status === 200) {
        let content = response.data.content.colPos0[0].content.bodytext;
        let gallery = response.data.content.colPos0?.[0]?.content?.gallery;
        let rows = gallery?.rows;
        let secondRow = rows?.[1];
        let columns = secondRow?.columns;
        let secondColumn = columns?.[1];
        let publicUrl = secondColumn?.publicUrl || ""; // Fallback to an empty string if publicUrl is not found

        let parsedContent = content.replace(
          'src="/fileadmin/',
          'src="' + settings.baseUrl + "/fileadmin/"
        );

        setHeader(response.data.meta.title);
        setBlogContent(parsedContent);
        setHeaderImage(publicUrl);
      }
    };
    loadData();
    window.scrollTo(0, 0);
  }, [splat]);

  return (
    <>
      <Header>
        <NotLoggedInHeader
          token={token}
          setToken={setToken}
          showLogin={showLogin}
          setShowLogin={setShowLogin}
        />
      </Header>
      <ContentWrapper setShowLogin={setShowLogin}>
        <Row>
          <Col md={6} className="offset-md-3">

              <article className="lh-lg text-body-emphasis" style={{backgroundColor: "#fff8f8"}}>
                <div className="ratio ratio-21x9 mb-3">
                  <img
                    className="card-img-top object-cover h-100"
                    src={headerImage}
                  />
                </div>
                <h2 className="card-body ">{header}</h2>
                <div
                  className="card-body flex-grow-0"
                  dangerouslySetInnerHTML={{ __html: blogContent }}
                />
              </article>
   
          </Col>
          <Col md={3}></Col>
        </Row>
      </ContentWrapper>
    </>
  );
};

export default Blogpage;
