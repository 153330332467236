import { useNavigate } from "react-router-dom";
import getSettings from "@serie3/common/config/settings";
import { Col, Row } from "react-bootstrap";
const BlogCard = ({ post }) => {
  const navigate = useNavigate();
  const settings = getSettings();

  const imageUrl = post.image
    ? settings.blogUrl + post.image
    : "https://felixtravelblog.de/fileadmin/templates/felix/travelblog/template/images/backgroundTravelBlog.jpg";
  const slug = post.slug && post.slug.replace("blogapi", "blog");

  return (
    <div
      className="card flex-row mb-3"
      onClick={() => {
        navigate(slug);
      }}
      style={{ cursor: "pointer" }}
    >
      <Row className="align-items-start">
        <Col xs={12} md={4} style={{ height: "100%" }}>
          <div className="w-100 flex-shrink-0" style={{ height: "100%" }}>
            <img
              className="img-fluid rounded-start rounded-0 rounded-md-start w-100"
              src={imageUrl}
              loading="lazy"
              style={{ height: "100%" }}
            />
          </div>
        </Col>
        <Col xs={12} md={8}>
          <div className="card-body d-flex flex-column w-100">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h3>{post.title}</h3>
            </div>
            <p>{post.description}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BlogCard;
