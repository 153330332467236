const firebaseConfig = {
  apiKey: "AIzaSyBwIxn7SYq6dmptYqkn5TlCojC6OH5aCrA",
  authDomain: "bewerbungshelferlein.firebaseapp.com",
  projectId: "bewerbungshelferlein",
  storageBucket: "bewerbungshelferlein.appspot.com",
  messagingSenderId: "196102889444",
  appId: "1:196102889444:web:7ef52e2e4755cc29bdf1f1",
  measurementId: "G-ZQ539MFVJY",
};

export default firebaseConfig;
