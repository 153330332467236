import { Container, Row, Col, Image } from "react-bootstrap";
import { Trans } from "react-i18next";
import SectionImage from "@serie3/bewerbungshelferleinapi/assets/FlowSection.webp";

const FlowSection = () => {
  return (
    <Container className="my-5 landingpageSection">
      <Row className="align-items-center mt-5">
        <Col md={5}>
          <h2>
            <strong>
              <Trans i18nKey="Landingpage.FlowSection.Title" />
            </strong>
          </h2>
          <p className="mt-3 text">
          <Trans i18nKey="Landingpage.FlowSection.Text" />
          </p>
        </Col>
        <Col md={6} className="text-center ms-md-5 ps-md-5 ms-0 ps-0">
          <Image
            src={SectionImage}
            alt="Resume and Laptop"
            fluid
            className="mb-3"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FlowSection;
