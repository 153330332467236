import useToken from "../../../security/useToken";
import Footer from "../../Public/Login/Landingpage/Footer";

const ContentWrapper = ({
  title = null,
  welcomeText = null,
  children,
  titleText = null,
  overlapping = true,
  rounded = true,
  style = {},
  setShowLogin = null,
}) => {
  const { token } = useToken();

  /** const backgroundStyle = {
    backgroundImage: `url('${waves}')`,
    backgroundSize: '100% auto', // Covers the width fully, height adjusts automatically
    backgroundRepeat: 'no-repeat', // Prevents repeating
    backgroundPosition: 'top left', // Starts from the upper left corner
  };*/

  return (
    <section
      id="content"
      className="content"
      //  style={token ? {} : backgroundStyle}
    >
      <div
        className={`content__header content__boxed ${
          overlapping ? "overlapping" : ""
        }
        ${rounded ? "" : "rounded-0"}`}
      >
        <div className="content__wrap">
          <h1 className="page-title mb-2">
            {typeof title === "function" ? title() : title}
          </h1>
          <h2 className="h5">
            {typeof welcomeText === "function" ? welcomeText() : welcomeText}
          </h2>
          <p>{typeof titleText === "function" ? titleText() : titleText}</p>
        </div>
      </div>
      <div className="content__boxed">
        <div className="content__wrap">{children}</div>
      </div>
      <Footer token={token} setShowLogin={setShowLogin}/>
    </section>
    
  );
};

export default ContentWrapper;
