import NewModal from "../../../Shared/Modals/NewModal";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import LoadApplications from "@serie3/bewerbungshelferleinapi/Modules/Applications/Domain/LoadApplications";
import { useState, useEffect } from "react";
import { ApplicationConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import ApplicationItem from "../../user/Applications/ApplicationItem";
import Carousel from "react-bootstrap/Carousel";
import Profile from "../../Profiles/Profile";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import crudApi from "@serie3/common/api/crudApi";
import { Row, Form, Button, Col, Modal } from "react-bootstrap";
import ApplicationComposeForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/ApplicationComposeForm";
import removeGenderTags from "@serie3/bewerbungshelferleinapi/Domain/removeGenderTags";
import TextArea from "../../../Shared/Form/TextArea";
import TextField from "../../../Shared/Form/TextField";
import ApplicationCheck from "./ApplicationCheck";
import DateField from "../../../Shared/Form/DateField";

const ComposeApplicationModal = ({
  show,
  setShow,
  applications,
  setApplications,
  setShowPreview,
  setPreviewId,
  setPreviewProfileTitle,
}) => {
  const { t } = useTranslation();
  const [todoApplications, setTodoApplications] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [choosenProfile, setChoosenProfile] = useState(null);
  const [application, setApplication] = useState(null);
  const [stepTitle, setStepTitle] = useState("Compose your application");
  const [profileTitle, setProfileTitle] = useState("");
  const [applicationFit, setApplicationFit] = useState(null);
  const [fitColor, setFitColor] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [applicantFullName, setApplicantFullName] = useState("");

  const loadTodoApplications = async () => {
    await LoadApplications(
      setTodoApplications,
      ApplicationConfig.endpoints.getTodo
    );
  };

  const loadProfiles = async () => {
    const endpoint = ProfileConfig.endpoints.getAll;
    await crudApi.getAll(endpoint, setProfiles, "profile");
  };

  const composeApplicationForm = ApplicationComposeForm.createApplicationForm(
    applications,
    setApplications,
    setShow,
    setShowSpinner
  );
  const composeFormik = useFormik(composeApplicationForm);

  const setStep1 = (application) => {
    setSlideIndex(1);
    setApplication(application);
  };

  const setStep2 = (
    profileId,
    applicationFit,
    applicationFitColor,
    fullName
  ) => {
    setSlideIndex(2);
    setApplicationFit(applicationFit);
    setFitColor(applicationFitColor);
    composeFormik.setFieldValue("profile.id", profileId);
    setApplicantFullName(fullName);

    const mailSubject = t("Backend.Applications.Modal.MailSubject", {
      jobTitle: application.job_title,
    });
    let mailText = t("Backend.Applications.Modal.MailText", {
      jobTitle: application.job_title,
    });
    mailText += fullName;

    application.profile = {"id": profileId};
    composeFormik.setFieldValue("application.id", application.id);
    composeFormik.setFieldValue("mail_subject", mailSubject);
    composeFormik.setFieldValue("mail_text", mailText);
    composeFormik.setFieldValue("mail_address", application.mail_address);
  };

  const setStep3 = () => {
    if (
      composeFormik.values.mail_address &&
      composeFormik.values.mail_address.trim() !== ""
    ) {
      setSlideIndex(3);
    } else {
      composeFormik.setFieldError("mail_address", "Email is required");
    }
  };

  const UseButton = () => {
    return (
      <Col
        className="d-flex justify-content-center align-items-center"
        style={{ color: fitColor }}
      >
        <Button className="btn btn-success">Use</Button>
      </Col>
    );
  };

  const UpdateProfileTitle = () => {
    return (
      <>
        {profileTitle !== (choosenProfile?.profiletitle || "") ? (
          <Button
            className="btn btn-primary btn-success"
            onClick={() => {
              if (choosenProfile) {
                setProfileTitle(choosenProfile.profiletitle);
                composeFormik.setFieldValue(
                  "updated_title",
                  choosenProfile.profiletitle
                );
              }
            }}
          >
            Reset profile title
          </Button>
        ) : (
          <Button
            className="btn btn-primary btn-success"
            onClick={() => {
              if (application) {
                let modifiedString = removeGenderTags(application.job_title);
                setProfileTitle(modifiedString);
                composeFormik.setFieldValue("updated_title", modifiedString);
              }
            }}
          >
            Update profile title
          </Button>
        )}
      </>
    );
  };

  useEffect(() => {
    const getProfile = async () => {
      let response = await crudApi.justGet(
        ProfileConfig.endpoints.show + composeFormik.values.profile.id
      );
      if (response.ok) {
        console.log(response.data);
        setChoosenProfile(response.data);
        setProfileTitle(response.data.profiletitle);
      }
    };

    switch (slideIndex) {
      case 0:
        setStepTitle("Compose your application");
        break;
      case 1:
        setStepTitle("Choose your profile");
        break;
      case 2:
        setStepTitle("Create your mail");
        getProfile();
        break;
      case 3:
        setStepTitle("Check and Send!");
        break;
    }
  }, [slideIndex]);

  const Save = () => {
    return (
      <>
        {slideIndex === 3 && (
          <Button
            onClick={() => composeFormik.handleSubmit()}
            className="btn btn-success btn btn-primary"
          >
            <i className="psi-mail-send fs-3 me-2"></i>
            <b>Send it!</b>
          </Button>
        )}

        {slideIndex === 2 && (
          <Button onClick={() => setStep3()} className="btn btn-success">
            Check and send
          </Button>
        )}
        {slideIndex > 0 && (
          <Button onClick={() => setSlideIndex(slideIndex - 1)}> Back </Button>
        )}
      </>
    );
  };

  const handleSlideLoad = () => {
    if (slideIndex === 1) {
      loadProfiles();
    }
  };

  const SpinnerModal = () => {
    return (
      <Modal show={showSpinner} setShow={setShowSpinner}>
        <Modal.Body
          style={{ textAlign: "center", backgroundColor: "transparent" }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div> {t("Backend.Applications.Modal.Send")}</div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <SpinnerModal />
      <NewModal
        show={show}
        setShow={setShow}
        ExtraButton={Save}
        title={stepTitle}
        onExit={() => {
          setSlideIndex(0);
          composeFormik.resetForm();
          setProfiles([]);
          setChoosenProfile(null);
        }}
        onShow={() => {
          loadTodoApplications();
        }}
      >
        <Carousel
          activeIndex={slideIndex}
          interval={null}
          controls={false}
          onSlid={handleSlideLoad}
          indicators={false}
        >
          <Carousel.Item>
            {Array.isArray(todoApplications) &&
              todoApplications.length > 0 &&
              todoApplications.map((application, index) => {
                return (
                  <ApplicationItem
                    application={application}
                    index={index}
                    key={application.id}
                    composeMode={true}
                    setStep1={setStep1}
                  />
                );
              })}
          </Carousel.Item>
          <Carousel.Item>
            <Row className="d-flex align-items-stretch">
              {Array.isArray(profiles) &&
                profiles.length > 0 &&
                profiles.map((profile, index) => {
                  return (
                    <Profile
                      profile={profile}
                      index={index}
                      composeMode={true}
                      setStep2={setStep2}
                      applicationId={application.id}
                      setPreviewId={setPreviewId}
                      setShowPreview={setShowPreview}
                      setProfileTitle={setPreviewProfileTitle}
                      UseButton={UseButton}
                    />
                  );
                })}
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Form.Group className={"form-inline"}>
              <Row className="mb-3">
                <Col className="col-2 fw-bold pt-2">{t("Backend.Applications.Mail.Address")}</Col>
                <Col>
                  <TextField
                    formik={composeFormik}
                    valueName={"mail_address"}
                    placeholder={"Address"}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="col-2 fw-bold pt-2">{t("Backend.Applications.Mail.Subject")}</Col>
                <Col>
                  <TextField
                    formik={composeFormik}
                    valueName={"mail_subject"}
                    placeholder={"Subject"}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="col-2 fw-bold pt-1">{t("Backend.Applications.Mail.Text")}</Col>
                <Col>
                  <TextArea
                    formik={composeFormik}
                    valueName={"mail_text"}
                    rows={15}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="col-2 fw-bold pt-2">{t("Backend.Applications.Composer.FollowUp")}</Col>
                <Col>
                  <DateField formik={composeFormik} valueName={"follow_up"} />
                </Col>
              </Row>
            </Form.Group>
          </Carousel.Item>
          <Carousel.Item>
            {application && (
              <ApplicationCheck
                setPreviewId={setPreviewId}
                setShowPreview={setShowPreview}
                setPreviewProfileTitle={setPreviewProfileTitle}
                application={application}
                profileTitle={profileTitle}
                mailAddress={composeFormik.values["mail_address"]}
                mailSubject={composeFormik.values["mail_subject"]}
                mailText={composeFormik.values["mail_text"]}
                choosenProfile={choosenProfile}
                fitColor={fitColor}
                UpdateProfileTitle={UpdateProfileTitle}
              />
            )}
          </Carousel.Item>
        </Carousel>
      </NewModal>
    </>
  );
};

export default ComposeApplicationModal;
