import NewModal from "../../../Shared/Modals/NewModal";
import { useTranslation } from "react-i18next";
import ApplicationCheck from "./ApplicationCheck";
import { Card, Row, Col, Button } from "react-bootstrap";
import KeyValueForm from "@serie3/common/Form/shared/KeyValueForm";
import { useState, useEffect } from "react";
import { ApplicationConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import { useFormik } from "formik";
import TextArea from "../../../Shared/Form/TextArea";
import listHandler from "@serie3/common/Domain/listHandler";
import crudApi from "@serie3/common/api/crudApi";

const ViewApplication = ({
  show,
  setShow,
  viewApplication,
  setViewApplication,
  setShowPreview,
  setPreviewId,
  setPreviewProfileTitle,
  fitColor,
  setShowAppointment,
  setEditApplication,
  appointments,
  setAppointments,
}) => {
  const { t } = useTranslation();
  const [notes, setNotes] = useState([]);

  const [application, setApplication] = useState(null);

  const noteForm = KeyValueForm.createKeyValueForm(
    notes,
    setNotes,
    null,
    null,
    ApplicationConfig.endpoints.notes.new,
    ApplicationConfig.endpoints.notes.edit
  );

  const noteFormik = useFormik(noteForm);

  const addNote = (e) => {
    noteFormik.setFieldValue("application.id", application.id);
    noteFormik.setFieldValue("key_name", "Comment");
    noteFormik.handleSubmit(e);
    e.preventDefault();
  };

  useEffect(() => {
    const getData = async () => {
      const response = await crudApi.justGet(
        ApplicationConfig.endpoints.show + viewApplication.id
      );
      if (response.ok) {
        setApplication(response.data);
        setNotes(response.data.notes);
        setAppointments(response.data.appointments);
      }
    };

    getData();
  }, [viewApplication.id]);

  const applicationTitle = application
    ? application.job_title + " | @" + application.hireing_company
    : "";

  return (
    application && (
      <NewModal
        show={show}
        setShow={setShow}
        title={applicationTitle}
        onExit={() => {
          setViewApplication(null);
        }}
      >
          <ApplicationCheck
            setPreviewId={setPreviewId}
            setShowPreview={setShowPreview}
            setPreviewProfileTitle={setPreviewProfileTitle}
            application={application}
            profileTitle={
              application.profile && application.profile.profileTitle
            }
            mailAddress={application.mail_address}
            mailSubject={application.mail_subject}
            mailText={application.mail_text}
            choosenProfile={application.profile}
            fitColor={fitColor}
          />
       
        <hr />
        <Card>
          <Card.Title>{t("Backend.Applications.Composer.Appointments.Title")}</Card.Title>
          <Card.Body>
            <Row>
              <Col className="mt-3 mb-3">
                <Button
                  onClick={() => {
                    setShowAppointment(true);
                    setEditApplication(application);
                  }}
                >
                {t("Backend.Applications.Composer.Appointments.AddButton")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul className="list-group">
                  {appointments &&
                    appointments
                      .slice()
                      .reverse()
                      .map((item, index, arr) => {
                        const originalIndex = arr.length - 1 - index;
                        return (
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center"
                            key={item.id}
                          >
                            <b className="me-3">
                              {item.appointment_date.replace("T", " - ")}:
                            </b>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.value &&
                                  item.value.replace(/\n/g, "<br />"),
                              }}
                            />
                            <Button
                              className="btn btn-icon btn-sm btn-danger btn-hover ms-3 ms-auto"
                              onClick={() =>
                                listHandler.deleteFromList(
                                  originalIndex,
                                  crudApi.del(
                                    ApplicationConfig.endpoints.appointments
                                      .delete,
                                    item.id
                                  ),
                                  setAppointments,
                                  appointments
                                )
                              }
                            >
                              <i className="pli-trash fs-5"></i>
                            </Button>
                          </li>
                        );
                      })}
                </ul>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <hr />
        <Card>
          <Card.Title>{t("Backend.Applications.Composer.Notes.Title")}</Card.Title>
          <Card.Body>
            <Row>
              <Col>
                <TextArea formik={noteFormik} rows={10} valueName={"value"} />
              </Col>
            </Row>
            <Row>
              <Col className="mt-3 mb-3">
                <Button
                  onClick={(e) => {
                    addNote(e);
                  }}
                >
                  {t("Backend.Applications.Composer.Notes.AddButton")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul className="list-group">
                  {notes &&
                    notes
                      .slice()
                      .reverse()
                      .map((item, index, arr) => {
                        const originalIndex = arr.length - 1 - index;
                        return (
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center"
                            key={item.id}
                          >
                            <b className="me-3">{item.date}:</b>{" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.value &&
                                  item.value.replace(/\n/g, "<br />"),
                              }}
                            />
                            <Button
                              className="btn btn-icon btn-sm btn-danger btn-hover ms-3 ms-auto"
                              onClick={() =>
                                listHandler.deleteFromList(
                                  originalIndex,
                                  crudApi.del(
                                    ApplicationConfig.endpoints.notes.delete,
                                    item.id
                                  ),
                                  setNotes,
                                  notes
                                )
                              }
                            >
                              <i className="pli-trash fs-5"></i>
                            </Button>
                          </li>
                        );
                      })}
                </ul>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <hr />
        <Card>
          <Card.Title>{t("Backend.Applications.Composer.Timeline.Title")}</Card.Title>
          <Card.Body>
            <ul className="list-group">
              {application.application_status &&
                application.application_status
                  .slice()
                  .reverse()
                  .map((item) => {
                    return (
                      <li className="list-group-item" key={item.id}>
                        <b>{item.created}:</b> {item.status}
                      </li>
                    );
                  })}
            </ul>
          </Card.Body>
        </Card>
      </NewModal>
    )
  );
};

export default ViewApplication;
