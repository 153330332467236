import ContentWrapper from "../../Shared/Basics/ContentWrapper";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Card from "../../Shared/Card";
import ApplicatonRow from "../Dashboard/ApplicatonRow";
import { useEffect, useState } from "react";
import {
  editAction,
  deleteAction,
  removeFromList,
} from "@serie3/bewerbungshelferleinapi/Modules/Applications/Domain/ApplicationCrudActions";
import ApplicationModal from "./Subcomponents/ApplicationModal";
import LoadApplications from "@serie3/bewerbungshelferleinapi/Modules/Applications/Domain/LoadApplications";
import { ApplicationConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import Table from "../../Shared/Table/Table";
import { calculateArchiveNumbers } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/CalculateDashboardNumber";
import ColorCard from "../../Shared/Cards/ColoredCards/ColorCard";

const Archive = () => {
  const [applications, setApplications] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editApplication, setEditApplication] = useState(null);
  const [updateView, setUpdateView] = useState(false);
  const [dashboardNumbers, setDashboardNumbers] = useState({
    noreply: 0,
    rejected: 0,
    canceled: 0,
    accepted: 0,
  });
  const { t } = useTranslation();

  useEffect(() => {
    const laodDashboardData = async () => {
      let allApplications = await LoadApplications(
        setApplications,
        ApplicationConfig.endpoints.archive
      );

      setDashboardNumbers({ ...calculateArchiveNumbers(applications) });
      setIsLoaded(false);
    };
    laodDashboardData();
  }, []);

  useEffect(() => {
    if (Array.isArray(applications) && applications.length > 0) {
      setDashboardNumbers({ ...calculateArchiveNumbers(applications) });
    }
  }, [applications, updateView]);

  return (
    <>
      <ApplicationModal
        show={showForm}
        setShow={setShowForm}
        applications={applications}
        setApplications={setApplications}
        application={editApplication}
      />
      <ContentWrapper
        title={t("Backend.Archive.Title")}
        welcomeText={t("Backend.Archive.Subtitle")}
        titleText={t("Backend.Archive.DashboardText")}
      >
        <Row>
          <Col sm={8}>
            <Card title={t("Backend.Archive.DoneTableHeader")}>
              <Table style={{ overflow: "visible", paddingBottom: "20px" }}>
                <thead>
                  <tr>
                    <th>{t("Backend.Archive.Table.Header.Title")}</th>
                    <th>{t("Backend.Archive.Table.Header.Company")}</th>
                    <th>{t("Backend.Archive.Table.Header.Link")}</th>
                    <th className="text-center">
                      {t("Backend.Archive.Table.Header.Status")}
                    </th>
                    <th className="text-center">
                      {t("Backend.Archive.Table.Header.Actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(applications) &&
                    applications.length > 0 &&
                    applications.map((application, index) => {
                      return (
                        <ApplicatonRow
                          application={application}
                          index={index}
                          key={application.id}
                          deleteAction={() => {
                            deleteAction(
                              application.id,
                              index,
                              setApplications,
                              applications
                            );
                          }}
                          editAction={() => {
                            editAction(
                              application,
                              setEditApplication,
                              setShowForm
                            );
                          }}
                          updateView={updateView}
                          setUpdateView={setUpdateView}
                          showDays={false}
                          removeFromList={() => {
                            removeFromList(
                              application,
                              applications,
                              setApplications,
                              "Restored"
                            );
                          }}
                          archive={true}
                        />
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col sm={4}>
            <Row>
              <Col sm={6}>
                <ColorCard
                  title={t("Backend.Dashboard.Accepted")}
                  color="success"
                  text={dashboardNumbers.accepted}
                />
              </Col>
              <Col sm={6}>
                <ColorCard
                  title={t("Backend.Dashboard.Canceled")}
                  color="info"
                  text={dashboardNumbers.canceled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <ColorCard
                  title={t("Backend.Dashboard.Rejected")}
                  color="warning"
                  text={dashboardNumbers.rejected}
                />
              </Col>

              <Col sm={6}>
                <ColorCard
                  title={t("Backend.Dashboard.NoReply")}
                  color="danger"
                  text={dashboardNumbers.noreply}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ContentWrapper>
    </>
  );
};

export default Archive;
