import ApplicationCompose from "../Entities/ApplicationCompose";
import * as Yup from "yup";
import crudApi from "@serie3/common/api/crudApi";
import { ApplicationConfig } from "../Resources/Endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createApplicationForm = (
  dataList,
  setDataFunction,
  setShowEdit,
  setShowSpinner,
) => {
  const validationSchema = Yup.object({
    profile: Yup.number().required("Required"),
    application: Yup.number().required("Required"),
    mail_text: Yup.string().required("Required"),
    mail_address: Yup.string().required("Required"),
    mail_subject: Yup.string().required("Required"),
  });

  const onSubmit = async (values, actions) => {
    setShowSpinner(true);
    const payload = JSON.stringify(values);
    let response = await crudApi.edit(
      ApplicationConfig.endpoints.compose,
      payload
    );
    listHandler.updateList(
      response,
      JSON.stringify(response.data),
      dataList,
      setDataFunction
    );
    if (response.status === 200) {
      setShowEdit(false);
    }
    setShowSpinner(false);
  };

  return {
    initialValues: ApplicationCompose,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  let ApplicationCompose = {
    profile: editData.profile,
    application: editData.application,
    mail_text: editData.mail_text,
    mail_address: editData.mail_address,
    mail_subject: editData.mail_subject,
    updated_title: editData.updated_title,
    fit_percentage: editData.fit_percentage,
    follow_up: editData.follow_up
  };

  return ApplicationCompose;
};

export default { createApplicationForm, setEditData };
