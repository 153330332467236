import { Form } from "react-bootstrap";

const DateField = ({ formik, formClass =  "form-inline", label, valueName, style = { width: "100%" } }) => {
  return (
    <Form.Group
      className={formClass}
      controlId={valueName}
      style={style}
    >
      <Form.Label>{label}:</Form.Label>
      <Form.Control
        type="datetime-local"
        name={valueName}
        placeholder={label}
        value={formik.values[valueName]}
        onChange={formik.handleChange}
        style={style}
      />
    </Form.Group>
  );
};

export default DateField;
