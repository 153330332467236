import { OverlayTrigger, Card, Row, Col, Tooltip } from "react-bootstrap";
import Profile from "../../Profiles/Profile";
import ApplicationCompose from "./ApplicationCompose";
import DateField from "../../../Shared/Form/DateField";
import { useFormik } from "formik";
import ApplicationForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/ApplicationForm";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ApplicationCheck = ({
  setPreviewId,
  setShowPreview,
  setPreviewProfileTitle,
  application = null,
  profileTitle = null,
  mailAddress = null,
  mailSubject = null,
  mailText = null,
  choosenProfile = null,
  fitColor = null,
  UpdateProfileTitle = null,
}) => {
  const { t } = useTranslation();

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
     {t("Backend.Applications.Composer.AIannouncement")}
    </Tooltip>
  );

  const applicationForms = ApplicationForm.createApplicationForm();
  const formik = useFormik(applicationForms);

  useEffect(() => {
    formik.setValues(ApplicationForm.setEditData(application));
  }, [application.id]);

  const updateFollowup = (e) => {
    formik.handleChange(e);
    formik.submitForm();
    e.preventDefault();
  };

  const followUpStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  };

  return application.profile && application.profile.id ? (
    <Card>
      <Card.Body>
        <Row>
          <Col className="col-8">
            <Row className="mb-3">
              <Col className="col-3" style={{ fontWeight: "bolder" }}>
                {t("Backend.Applications.Mail.Address")}
              </Col>
              <Col>{mailAddress}</Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-3" style={{ fontWeight: "bolder" }}>
              {t("Backend.Applications.Mail.Subject")}
              </Col>
              <Col>{mailSubject}</Col>
            </Row>
          </Col>
          <Col className="text-end">
            <Col className="col-12" style={{ fontWeight: "bolder" }}>
              {t("Backend.Applications.Composer.FollowUp")}
            </Col>
            <Col className="col-8 mt-3 ms-auto">
              <DateField
                formik={formik}
                valueName={"follow_up"}
                style={followUpStyle}
                onChangeFunction={updateFollowup}
              />
            </Col>
          </Col>
        </Row>
        <hr />
        <Row className="mb-5">
          <Col className="col-2" style={{ fontWeight: "bolder" }}>
            {t("Backend.Applications.Mail.Text")}
          </Col>
          <Col
            style={{
              backgroundColor: "rgba(117,134,142,0.05)",
              padding: "12px 15px",
            }}
            dangerouslySetInnerHTML={{
              __html: mailText && mailText.replace(/\n/g, "<br />"),
            }}
          />
        </Row>
        <h5 className="mt-3">{t("Backend.Applications.Composer.ApplicationDataTitle")}</h5>
        <hr />
        <Row>
          {choosenProfile && (
            <Profile
              index={1}
              profile={choosenProfile}
              composeMode={true}
              overwriteTitle={profileTitle}
              setPreviewId={setPreviewId}
              setShowPreview={setShowPreview}
              setProfileTitle={setPreviewProfileTitle}
            />
          )}
          <Col className="d-flex flex-column justify-content-center align-items-center">
            <Row className="w-100">
              <Col className="d-flex justify-content-center">
                <div
                  className="doughnut-container"
                  style={{
                    background: `conic-gradient(${fitColor} 0% 0%, gray 100% 100%)`,
                  }}
                >
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <div className="doughnut-inner" style={{ color: fitColor }}>
                      <small>
                        AI
                        <br />
                        soon
                      </small>
                    </div>
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mt-3">
              <Col className="d-flex justify-content-center">
                {UpdateProfileTitle && <UpdateProfileTitle />}
              </Col>
            </Row>
          </Col>
          {application && <ApplicationCompose application={application} />}
        </Row>
      </Card.Body>
    </Card>
  ) : (
    <>
      <Row>
        <Col>
          <h3>{t("Backend.Applications.Composer.Notused.Title")}</h3>
          <span>{t("Backend.Applications.Composer.Notused.Text")}</span>
        </Col>
      </Row>
      <Row>
        <Col className="col-12 mt-3" style={{ fontWeight: "bolder" }}>
          {t("Backend.Applications.Composer.FollowUp")}
          <DateField
            formik={formik}
            valueName={"follow_up"}
            style={followUpStyle}
            onChangeFunction={updateFollowup}
          />
        </Col>
      </Row>
    </>
  );
};

export default ApplicationCheck;
