import NewModal from "../../../Shared/Modals/NewModal";
import ApplicationForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/ApplicationForm";
import { useTranslation } from "react-i18next";
import SaveButton from "../../../Shared/Buttons/SaveButton";
import { useFormik } from "formik";
import Application from "@serie3/bewerbungshelferleinapi/Modules/Profile/Entities/Application";
import ApplicationFormComponent from "./ApplicationFormComponent";

const ApplicationModal = ({
  show,
  setShow,
  applications,
  setApplications,
  application,
}) => {
  const { t } = useTranslation();
  const editApplicationForm = ApplicationForm.createApplicationForm(
    applications,
    setApplications,
    setShow
  );
  const formik = useFormik(editApplicationForm);

  const Save = () => {
    return <SaveButton formik={formik} />;
  };

  return (
    <NewModal
      show={show}
      setShow={setShow}
      ExtraButton={Save}
      title={formik.values["job_title"]}
      onExit={() => {formik.resetForm()}}
      onShow={() => {
        application
          ? formik.setValues(ApplicationForm.setEditData(application))
          : formik.setValues(Application);
      }}
    >
      <ApplicationFormComponent formik={formik} />
    </NewModal>
  );
};

export default ApplicationModal;
