const PublicProfilesConfig = {
  Profiles: {
    endpoints: {
      publicProfiles: "publicprofiles/",
      profileDetails: "publicprofiles/showpublicprofiles/",
      matchingJobs: "publicprofiles/profiles/",
      search: "publicprofiles/search",
    },
  },
};

const ProfileConfig = {
  endpoints: {
    settingsedit: "usersettings/edit",
    settingsget: "usersettings/show",
    getAll: "profile/",
    new: "profile/",
    edit: "profile/edit",
    show: "profile/show/",
    delete: "profile/delete/",
    clone: "profile/clone/",
    calculateFit: "profile/calculatefit/",
    download: "profile/download/",
    profilePicture: "profile/profilePicture/",
    matchingJobs: "profile/jobs/",
    systemProfile: "profile/systemprofile",
    editSystemProfile: "profile/systemprofile/edit",
    htmlPreview: "profile/htmlpreview/",
    socialMedia: {
      getAll: "profile/socialmedia/",
      new: "profile/socialmedia/",
      edit: "profile/socialmedia/edit",
      show: "profile/socialmedia/show/",
      delete: "profile/socialmedia/delete/",
    },
    education: {
      getAll: "profile/education/",
      new: "profile/education/",
      edit: "profile/education/edit",
      show: "profile/education/show/",
      delete: "profile/education/delete/",
    },
    workexperience: {
      getAll: "profile/workexperience/",
      new: "profile/workexperience/",
      edit: "profile/workexperience/edit",
      show: "profile/workexperience/show/",
      delete: "profile/workexperience/delete/",
      addJobTask: "profile/workexperience/addJobTask",
      editJobTask: "profile/workexperience/editJobTask",
      deleteJobTask: "profile/workexperience/deleteJobTask/"
    },
    skills: {
      new: "profile/skill/",
      edit: "profile/skill/edit",
      show: "profile/skill/show/",
      delete: "profile/skill/delete/",
    },
  },
  datafield: "profile",
};

const ApplicationConfig = {
  endpoints: {
    getAll: "profile/applications/",
    archive: "profile/applications/archive",
    new: "profile/applications/",
    show: "profile/applications/show/",
    edit: "profile/applications/edit",
    delete: "profile/applications/delete/",
    getTodo: "profile/applications/todo",
    compose: "profile/applications/compose",
    notes: {
      new: "/application/notes/",
      show: "/application/notes/show/",
      edit: "/application/notes/edit",
      delete: "/application/notes/delete/",
    },
    appointments: {
      new: "/application/appointment/",
      show: "/application/appointment/show/",
      edit: "/application/appointment/edit",
      delete: "/application/appointment/delete/",
    }
  },
  datafield: "applications",
};

export { PublicProfilesConfig, ProfileConfig, ApplicationConfig };
