import { Row, Col } from "react-bootstrap";
import Header from "../../Shared/Basics/Header";
import ContentWrapper from "../../Shared/Basics/ContentWrapper";
import { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import blogclient from "@serie3/common/api/blogClient";
import NotLoggedInHeader from "../NotLoggedIn/NotLoggedInHeader";

const BlogList = ({ setToken, token, showLogin, setShowLogin }) => {
  const [blogPosts, setBlogPosts] = useState([]);
  useEffect(() => {
    const loadData = async () => {
      const response = await blogclient.get('/');
      if (response.status === 200) {
        setBlogPosts([...response.data.subpages]);
      }
    };
    loadData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <NotLoggedInHeader token={token} setToken={setToken}  showLogin={showLogin} setShowLogin={setShowLogin}/>
      </Header>
      <ContentWrapper setShowLogin={setShowLogin}>
        <Row>
          <Col md={6} className="offset-md-3">
            {blogPosts.map((post, index) => {
              return <BlogCard post={post} key={index}/>;
            })}
          </Col>
          <Col md={3}></Col>
        </Row>
      </ContentWrapper>
    </>
  );
};

export default BlogList;
