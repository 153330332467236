import { Button } from "react-bootstrap";

const ApplicationHeader = ({Button}) => {
  return (
    <div className="d-md-flex align-items-center mb-3">
      <div className="form-group">
        {Button && <Button />}
      </div>
    </div>
  );
};

export default ApplicationHeader;
