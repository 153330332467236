import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import "../../../../styles/footer.scss";
import { Container, Row, Col } from "react-bootstrap";
import { logoColor } from "../../../../assets/img/logoColor";
import { useNavigate } from "react-router-dom";

const Footer = ({ token = null, setShowLogin }) => {
  const navigate = useNavigate();

  const headerStyle = {
    color: "var(--White, #FFF)",
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "180%", // 2.025rem
    letterSpacing: "0.045rem",
    paddingLeft: "15px",
  };

  const textStyle = {
    color: "#DEE4F2",
    fontFamily: "Manrope, sans-serif",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "2.25rem", // 3.4rem
  };

  const NonLoggedinFooter = () => {
    return (
      <div
        style={{
          backgroundColor: "#25476a",
          color: "white",
          padding: "40px 0",
          fontSize: "1rem",
        }}
      >
        <Container>
          <Row>
            {/* Logo and Contact Section */}
            <Col md={3} className="mb-4">
              <img
                src={logoColor}
                alt="Logo"
                style={{ marginBottom: "20px" }}
              />
              <p>
                <Trans i18nKey="Footer.Links.Contact" />
              </p>
              <p>felix@serie3.de</p>
              <div className="d-flex mt-3"></div>
              <p className="mt-4">Copyright ©2024 bewerbungshelferlein.de</p>
            </Col>

            {/* Links Section */}
            <Col md={3}>
              <h5 style={headerStyle}>
                <Trans i18nKey="Footer.Links.Company" />
              </h5>
              <ul className="list-unstyled">
                <ul className="list-unstyled">
                <li style={textStyle}>
                    <Link
                      className="nav-link link-hover-effect"
                      to={"/blog/prices"}
                    >
                      <Trans i18nKey="Footer.Links.Price" />
                    </Link>
                  </li>
                  <li style={textStyle}>
                    <Link
                      className="nav-link link-hover-effect"
                      to={"/blog/so-benutzt-du-das-bewerbungshelferlein"}
                    >
                      <Trans i18nKey="Footer.Links.Howitworks" />
                    </Link>
                  </li>
                  <li style={textStyle}>
                    <Link
                      className="nav-link link-hover-effect"
                      to={"/blog/datenschutz"}
                    >
                      <Trans i18nKey="Footer.Privacylink" />
                    </Link>
                  </li>
                  <li style={textStyle}>
                    <Link
                      className="nav-link link-hover-effect"
                      to={"/blog/impressum"}
                    >
                      <Trans i18nKey="Footer.Imprintlink" />
                    </Link>
                  </li>
                </ul>
              </ul>
            </Col>

            {/* Company Section */}
            <Col md={3}>
              <h5 style={headerStyle}>
                <Trans i18nKey="Footer.Links.TipsAndTricks" />
              </h5>
              <ul className="list-unstyled">
                <li style={textStyle}>
                  <Link className="nav-link link-hover-effect" to={"/blog"}>
                    <Trans i18nKey="Footer.Links.Blog" />
                  </Link>
                </li>
                <li style={textStyle}>
                  <Link
                    className="nav-link link-hover-effect"
                    to={"/blog/funktionen"}
                  >
                    <Trans i18nKey="Footer.Links.Features" />
                  </Link>
                </li>
                <li style={textStyle}>
                  <Link
                    className="nav-link link-hover-effect"
                    to={"/blog/impressum"}
                  >
                    <Trans i18nKey="Footer.Links.Contact" />
                  </Link>
                </li>
                <li style={textStyle}>
                  <Link
                    className="nav-link link-hover-effect"
                    to={"/blog/kooperieren-mit-dem-bewerbungshelferlein"}
                  >
                    <Trans i18nKey="Footer.Links.Cooperate" />
                  </Link>
                </li>
              </ul>
            </Col>

            {/* Account Section */}
            <Col md={3}>
              <h5 style={headerStyle}>Account</h5>
              <ul className="list-unstyled">
                <li style={textStyle}>
                  <Link
                    className="nav-link link-hover-effect"
                    to={"/"}
                    onClick={(e) => {
                      e.preventDefault();
                      typeof setShowLogin === 'function' ?  setShowLogin(true) : navigate('/') ;
                    }}
                  >
                    <Trans i18nKey="Login.Login" />
                  </Link>
                </li>
                <li style={textStyle}>
                  <Link className="nav-link link-hover-effect" to={"/signup"}>
                    <Trans i18nKey="Signup.Signup" />
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const LoggedinFooter = () => {
    return (
      <footer className="mt-auto footer">
        <div className="content__boxed">
          <div
            className="content__wrap  d-flex flex-column flex-md-row align-items-md-center"
            style={{ paddingTop: "0.4rem", paddingBottom: "0.5rem" }}
          >
            <div className="text-nowrap fs-5">
              <Link
                className="nav-link link-hover-effect"
                to={"/blog/datenschutz"}
              >
                <Trans i18nKey="Footer.Privacylink" />
              </Link>
              <Link
                className="nav-link link-hover-effect"
                to={"/blog/impressum"}
              >
                <Trans i18nKey="Footer.Imprintlink" />
              </Link>

              <Link className="nav-link link-hover-effect" to={"/"}>
                <Trans i18nKey="Footer.Startpage" />
              </Link>

              <Link className="nav-link link-hover-effect" to={"/blog"}>
                <Trans i18nKey="Footer.Links.Blog" />
              </Link>

              <Link className="nav-link link-hover-effect" to={"/blog/prices"}>
                <Trans i18nKey="Footer.Links.Price" />
              </Link>
            </div>
          </div>
        </div>
      </footer>
    );
  };

  return token ? <LoggedinFooter /> : <NonLoggedinFooter />;
};

export default Footer;
