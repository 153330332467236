const Application = {
    hireing_company: '',
    job_title: '',
    link: '',
    page: '',
    location: '',
    recipient_name: '',
    mail_address: '',
    follow_up: '',
    mail_subject: ''
}

export default Application;