import { useTranslation } from "react-i18next";
import ContentWrapper from "../../Shared/Basics/ContentWrapper";
import ProfileHeader from "./Subcomponents/ProfileHeader";
import StyledTabs from "../../Shared/Tabs/StyledTabs";
import { Tab } from "react-bootstrap";
import ProfileForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/ProfileForm";
import { useFormik } from "formik";
import ProfileAddressForm from "./Subcomponents/Forms/ProfileAddressForm";
import ProfileBasicsForm from "./Subcomponents/Forms/ProfileBascisForm";
import ProfilePersonalForm from "./Subcomponents/Forms/ProfilePersonalForm";
import { useEffect, useState } from "react";
import loadData from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/LoadData";
import ProfileSkillsForm from "./Subcomponents/Forms/ProfileSkillsForm";
import ProfileEducationForm from "./Subcomponents/Forms/ProfileEducationForm";
import ProfileWorkForm from "./Subcomponents/Forms/ProfileWorkForm";
import ProfileSettings from "./Subcomponents/Forms/ProfileSettings";
import { useParams } from "react-router-dom";
import ResponsiveTabTitle from "../../Shared/Tabs/ResponsiveTabTitle";
import {
  faDatabase,
  faPerson,
  faAddressBook,
  faGraduationCap,
  faHandshake,
  faCog,
  faBookOpenReader,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import PricesAndAvailability from "./Subcomponents/Forms/PricesAndAvailability";

const ProfileEdit = () => {
  const { t } = useTranslation();
  const [languages, setLanguages] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [originalSkillList, setOriginalSkillList] = useState([]);
  const [editProfile, setEditProfile] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchingFor, setSearchingFor] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [profileType, setProfileType] = useState(false);
  const [profileLang, setProfileLang] = useState('de');

  let { id } = useParams();

  const editProfileForm = ProfileForm.createProfileForm(
    setOriginalSkillList,
    setQualifications,
    setLanguages,
    setSearchingFor,
    setSocialMediaList
  );
  const formik = useFormik(editProfileForm);

  const Header = () => {
    return (
      <ProfileHeader
        formik={formik}
        socialMediaList={socialMediaList}
        profile={editProfile}
      />
    );
  };

  useEffect(() => {
    loadData(
      id,
      setEditProfile,
      setOriginalSkillList,
      setLanguages,
      setSearchingFor,
      setSocialMediaList,
      formik,
      setIsLoaded,
      ProfileForm,
      setProfileType,
      setProfileLang
    );
  }, [id]);

  return isLoaded ? (
    <>
      <ContentWrapper title={Header} overlapping={false} rounded={false}>
        <div className="flex-fill">
          <StyledTabs defaultKey="basics">
            <Tab
              eventKey="basics"
              title={
                <ResponsiveTabTitle
                  icon={faDatabase}
                  fullTitle={t("Backend.Profiles.Edit.Tabs.Titles.Basics")}
                />
              }
              className="nav nav-tabs"
            >
              <ProfileBasicsForm
                formik={formik}
                searchingFor={searchingFor}
                setSearchingFor={setSearchingFor}
              />
            </Tab>
            {profileType === true && (
              <Tab
                eventKey="rates"
                title={
                  <ResponsiveTabTitle
                    icon={faMoneyBill}
                    fullTitle={t("Backend.Profiles.Edit.Tabs.Titles.Rates")}
                  />
                }
                className="nav nav-tabs"
              >
                <PricesAndAvailability formik={formik} />
              </Tab>
            )}
            <Tab
              title={
                <ResponsiveTabTitle
                  icon={faPerson}
                  fullTitle={t("Backend.Profiles.Edit.Tabs.Titles.PersonalTab")}
                />
              }
              eventKey="personal"
              className="nav nav-tabs"
            >
              <ProfilePersonalForm
                formik={formik}
                languages={languages}
                setLanguages={setLanguages}
              />
            </Tab>
            <Tab
              title={
                <ResponsiveTabTitle
                  icon={faAddressBook}
                  fullTitle={t("Backend.Profiles.Edit.Tabs.Titles.AddressTab")}
                />
              }
              eventKey="address"
              className="nav nav-tabs"
            >
              <ProfileAddressForm
                formik={formik}
                profile={editProfile}
                socialMediaList={socialMediaList}
                setSocialMediaList={setSocialMediaList}
              />
            </Tab>
            <Tab
              title={
                <ResponsiveTabTitle
                  icon={faBookOpenReader}
                  fullTitle={t("Backend.Profiles.Edit.Tabs.Titles.SkillsTab")}
                />
              }
              eventKey="skills"
              className="nav nav-tabs"
            >
              <ProfileSkillsForm
                formik={formik}
                originalSkillList={originalSkillList}
                setOriginalSkillList={setOriginalSkillList}
                qualifications={qualifications}
                setQualifications={setQualifications}
              />
            </Tab>
            <Tab
              title={
                <ResponsiveTabTitle
                  icon={faGraduationCap}
                  fullTitle={t(
                    "Backend.Profiles.Edit.Tabs.Titles.EducationTab"
                  )}
                />
              }
              eventKey="education"
              className="nav nav-tabs"
            >
              <ProfileEducationForm profile={editProfile} />
            </Tab>
            <Tab
              title={
                <ResponsiveTabTitle
                  icon={faHandshake}
                  fullTitle={t(
                    "Backend.Profiles.Edit.Tabs.Titles.WorkhistoryTab"
                  )}
                />
              }
              eventKey="workhistory"
              className="nav nav-tabs"
            >
              <ProfileWorkForm profile={editProfile} />
            </Tab>
            <Tab
              title={
                <ResponsiveTabTitle
                  icon={faCog}
                  fullTitle={t("Backend.Profiles.Edit.Tabs.Titles.Settings")}
                />
              }
              eventKey="settings"
              className="nav nav-tabs"
            >
              <ProfileSettings
                formik={formik}
                profileType={profileType}
                setProfileType={setProfileType}
                profileLang={profileLang}
                setProfileLang={setProfileLang}
              />
            </Tab>
          </StyledTabs>
        </div>
        <div className="vr d-none"></div>
        <div className="w-md-200px flex-shrink-0">
          <h5 className="d-flex justify-content-between align-items-center">
            {t("Backend.Profiles.Edit.Jobs.Title")}
          </h5>
        </div>
      </ContentWrapper>
    </>
  ) : null;
};

export default ProfileEdit;
