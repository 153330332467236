const baseURLs = {
  dev: "http://dev.bewerbungshelferlein.de",
  stage: "https://stage.bewerbungshelferlein.de",
  //demo: "https://demo.bewerbungshelferlein.de",
  prod: "https://bewerbungshelferlein.de",
};

const settings = {
  dev: {
    baseUrl: baseURLs.dev,
    apiUrl: baseURLs.dev + "/api/",
    blogApiUrl: baseURLs.prod + "/blogapi",
    blogUrl: baseURLs.prod,
    stripePubKey:
      "pk_test_51NNxyqCJGCgzEfQXYSm2T6wDtyW6ZkHW8VrcKs0EdkyIDjcwehjkTBxoAd0NS8PTqRJHuVzb5i11DRCwET7wdvha00jkHuB9Pn",
    pricingTables: {
      billing: "prctbl_1NOLG5CJGCgzEfQXaxQnF2xi",
      crm: "prctbl_1NPoBqCJGCgzEfQX6e0xkXtY",
      profile: "prctbl_1NYAhmCJGCgzEfQX2wQk9JHp",
      timetracking: "prctbl_1NXkEkCJGCgzEfQXvDyw2ydG",
      projects: "prctbl_1NXkFBCJGCgzEfQX5PxhfXlD",
      document: "prctbl_1NXkFwCJGCgzEfQXacC8Ah19",
      jobs: "prctbl_1NXkGXCJGCgzEfQXm3lORVv6",
    },
  },
  stageing: {
    baseUrl: baseURLs.stage,
    apiUrl: baseURLs.stage + "/api",
    blogApiUrl: baseURLs.prod + "/blogapi/",
    blogUrl: baseURLs.prod,
    stripePubKey:
      "pk_test_51NNxyqCJGCgzEfQXYSm2T6wDtyW6ZkHW8VrcKs0EdkyIDjcwehjkTBxoAd0NS8PTqRJHuVzb5i11DRCwET7wdvha00jkHuB9Pn",
    pricingTables: {
      billing: "",
      crm: "",
      profile: "",
      timetracking: "",
      projects: "",
      document: "",
      jobs: "",
    },
  },
  stage: {
    baseUrl: baseURLs.prod,
    apiUrl: baseURLs.stage + "/api/",
    blogApiUrl: baseURLs.prod + "/blogapi/",
    blogUrl: baseURLs.prod,
    stripePubKey: "",
    pricingTables: {
      billing: "",
      crm: "",
      profile: "",
      timetracking: "",
      projects: "",
      document: "",
      jobs: "",
    },
  },
  prod: {
    baseUrl: baseURLs.prod,
    apiUrl: baseURLs.prod + "/api/",
    blogApiUrl: baseURLs.prod + "/blogapi/",
    blogUrl: baseURLs.prod,
    stripePubKey:
      "pk_live_51NNxyqCJGCgzEfQX3LlTsrnxWZQslE2yf0YO0pnO2ZwLa7KQHfh6sZ5hawrACEYP4HmTaKHR8kIR3tOK2pZhLfQK00uNH8coRe",
    pricingTables: {
      billing: "",
      crm: "",
      profile: "",
      timetracking: "",
      projects: "",
      document: "",
      jobs: "",
    },
  },

};

const getSettings = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "dev":
      return settings.dev;
    case "stage":
      return settings.stageing;
    case "stage":
      return settings.stage;
    case "prod":
      return settings.prod;
    default:
      return settings.dev;
  }
};

export default getSettings;
