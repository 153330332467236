import { Container, Row, Col, Image } from "react-bootstrap";
import { Trans } from "react-i18next";
import SectionImage from "@serie3/bewerbungshelferleinapi/assets/FeatureSection.webp";

const FeatureSection = () => {


  
  return (
    <Container className="my-5 landingpageSection">
      <Row className="align-items-center">
        <Col md={6} className="text-center">
          <Image
            src={SectionImage}
            alt="Resume and Laptop"
            fluid
            className="mb-3"
          />
        </Col>

        <Col md={5} className="ms-md-5 ps-md-5 ms-0 ps-0">
          <h2 style={{hyphens: "auto"}}>
            <strong><Trans i18nKey="Landingpage.FeatureSection.Title" /></strong>
          </h2>
          <p className="mt-3 text">
            <Trans i18nKey="Landingpage.FeatureSection.Text" /> </p>
        </Col>
      </Row>
    </Container>
  );
};

export default FeatureSection;
