import KeyValue from "../../Entity/shared/KeyValue";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import ListHandler from "@serie3/common/Domain/listHandler";

const createKeyValueForm = (
  dataList,
  setDataFunction,
  showErrorFunction,
  setShowEdit,
  newEndpoint,
  editEndpoint,
  entityList
) => {
  const onSubmit = async (values, actions) => {
    let response;
    if (values.id > 0) {
      if (typeof values.date === "undefined") {
        values.date = new Date().toISOString().split("T")[0];
      }
      let payload = JSON.stringify(values, null, 2);
      response = await CrudApi.edit(editEndpoint, payload);
      if (response.status === 200) {
        ListHandler.updateList(
          response,
          payload,
          dataList,
          setDataFunction,
          showErrorFunction
        );
      }
    } else {
      if (typeof values.date === "undefined") {
        values.date = new Date().toISOString().split("T")[0];
      }
      let payload = JSON.stringify(values, null, 2);
      response = await CrudApi.createNew(newEndpoint, payload);
      if (response.status === 200) {
        ListHandler.newToList(
          response,
          dataList,
          setDataFunction,
          showErrorFunction
        );
      }
    }

    if (response.status === 200) {
      if (typeof entityList !== "undefined") {
        entityList.push(response.data);
      }
    }

    if (typeof setShowEdit === "function") {
      setShowEdit(false);
    }
    actions.resetForm(KeyValue);
  };

  return {
    initialValues: KeyValue,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  let KeyValue = {
    id: editData.id,
    key_name: editData.key_name,
    value: editData.value,
  };
  return KeyValue;
};

export default { createKeyValueForm, setEditData };
