import { Button } from "react-bootstrap";
import crudApi from "@serie3/common/api/crudApi";
import UserConfig from "@serie3/bewerbungshelferleinapi/Modules/User/Resources/Endpoints";
import { useEffect, useState } from "react";
import ToastMessages from "@serie3/common/Shared/ToastMessages";
import getIsPayedUser from "@serie3/bewerbungshelferleinapi/Modules/User/Domain/getIsPayedUser";
import { useTranslation } from "react-i18next";
import StripePricingTable from "../../../Shared/Payment/StripePricingTable";

const GmailButton = ({ payedUser = false }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isPayedUser, setIsPayedUser] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const isConnected = async () => {
      if (!payedUser) {
        await getIsPayedUser(setIsPayedUser);
      }

      let response = await crudApi.justGet(
        UserConfig.endpoints.social.gmail.isConneted
      );
      if (response.ok && response.data.connected) {
        setIsConnected(true);
      }
    };

    isConnected();
  }, []);

  const getAuthUrl = async () => {
    let response = await crudApi.justGet(
      UserConfig.endpoints.social.gmail.getAuthUrl
    );
    let authUrl = response.data.authUrl;
    window.location.href = authUrl;
  };

  const disconnect = async () => {
    let response = await crudApi.justGet(
      UserConfig.endpoints.social.gmail.disconnect
    );
    if (response.ok) {
      ToastMessages.SuccessfulSave();
      setIsConnected(false);
    } else {
      ToastMessages.Error();
    }
  };

  const Connect = () => {
    return (
      <Button className="btn-success" onClick={() => getAuthUrl()}>
        {t("Backend.Settings.Tabs.Connections.ConnectToGmail")}
      </Button>
    );
  };

  const Disconnect = () => {
    return (
      <Button className="btn-danger" onClick={() => disconnect()}>
        {t("Backend.Settings.Tabs.Connections.DisconnectfromGmail")}
      </Button>
    );
  };

  return isPayedUser || payedUser ? (
    isConnected ? (
      <Disconnect />
    ) : (
      <Connect />
    )
  ) : (
    <StripePricingTable priceTableId={"prctbl_1PijqTEr0zAuiASD8BR7rLoz"} />
  );
};

export default GmailButton;
